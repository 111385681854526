import { useState } from '@u3u/vue-hooks';
import { planType, organizationRoles } from '@packages/mongodb';

export const isUserHasOrganization = (user = null) => {
  if (user) {
    return (user !== null && user?.organization !== null && typeof user?.organization === 'object') === true;
  }
  const { user: userFromState } = useState('auth', ['user']);

  return (
    (userFromState.value !== null &&
      userFromState.value?.organization !== null &&
      typeof userFromState.value?.organization === 'object') === true
  );
};

export const userOrganizationPlanType = () => {
  const { user } = useState('auth', ['user']);
  return user.value?.organization?.planType;
};

export const isBasicOrgPlan = (user = null) => {
  if (user) {
    return user?.organization?.planType === planType.BASIC;
  }
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.planType === planType.BASIC;
};

export const isProOrgPlan = (user = null) => {
  if (user) {
    return user?.organization?.planType === planType.PRO;
  }
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.planType === planType.PRO;
};

// org user roles
export const isUserHasOrgViewerRole = (user = null) => {
  if (user) {
    return user?.organizationRole === organizationRoles.VIEWER.value;
  }
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organizationRole === organizationRoles.VIEWER.value;
};

export const isUserHasOrgContentManagerRole = () => {
  const { user } = useState('auth', ['user']);
  return user.value?.organizationRole === organizationRoles.CONTENT_MANAGER.value;
};

export const isUserHasOrgAdminRole = (user = null) => {
  if (user) {
    return user?.organizationRole === organizationRoles.ADMIN.value;
  }
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organizationRole === organizationRoles.ADMIN.value;
};

export const isUserHasOrgAdminOrContentManagerRole = (user = null) => {
  if (user) {
    return user?.organizationRole === organizationRoles.ADMIN.value || user?.organizationRole === organizationRoles.CONTENT_MANAGER.value;
  }
  const { user: userFromState } = useState('auth', ['user']);
  return (
    userFromState.value?.organizationRole === organizationRoles.ADMIN.value ||
    userFromState.value?.organizationRole === organizationRoles.CONTENT_MANAGER.value
  );
};

export const isUserExternalOrganization = (user = null) => {
  if (user) {
    return user?.organization?.isExternalOrganization === true;
  }
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.isExternalOrganization === true;
};

export const isUserCanVisitOrganization = (user = null) => {
  if (user) {
    return isUserHasOrganization(user) && !isUserExternalOrganization(user) && isUserHasOrgAdminRole(user);
  }
  return isUserHasOrganization() && !isUserExternalOrganization() && isUserHasOrgAdminRole();
};

export const isOrganizationHasAutoLogoutEnable = (user = null) => {
  if (user) {
    return user !== null && user?.organization !== null && user?.organization?.autoLogout;
  }
  const { user: userFromState } = useState('auth', ['user']);

  return userFromState.value !== null && userFromState.value?.organization !== null && userFromState.value?.organization?.autoLogout;
};

export const getUserAutoLogoutTime = (user = null) => {
  if (user && user?.organization !== null && user?.organization?.autoLogout) {
    return user?.organization?.autoLogoutTimeout || 0;
  }
  const { user: userFromState } = useState('auth', ['user']);

  if (userFromState.value && userFromState.value?.organization !== null && userFromState.value?.organization?.autoLogout) {
    return userFromState?.value?.organization?.autoLogoutTimeout || 0;
  }
};

export const isOrganizationHasSessionMaxTime = (user = null) => {
  if (user) {
    return user !== null && user?.organization !== null && user?.organization?.sessionMaxTime;
  }
  const { user: userFromState } = useState('auth', ['user']);

  return userFromState.value !== null && userFromState.value?.organization !== null && userFromState.value?.organization?.sessionMaxTime;
};

export const getUserSessionMaxTime = (user = null) => {
  if (user && user?.organization !== null && user?.organization?.sessionMaxTime) {
    return user?.organization?.sessionMaxTime || 0;
  }
  const { user: userFromState } = useState('auth', ['user']);

  if (userFromState.value && userFromState.value?.organization !== null && userFromState.value?.organization?.sessionMaxTime) {
    return userFromState?.value?.organization?.sessionMaxTime || 0;
  }
};

export const getUserAutoLogoutURL = () => {
  const { user: userFromState } = useState('auth', ['user']);

  if (userFromState.value && userFromState.value?.organization !== null && userFromState.value?.organization?.autoLogout) {
    const autoLogoutUrl = userFromState?.value?.organization?.autoLogoutUrl;
    return autoLogoutUrl ? checkAndModifyAutoLogoutURL(autoLogoutUrl) : null;
  }
};

const checkAndModifyAutoLogoutURL = (url: string) => {
  return url?.startsWith('/') ? url : `/${url}`;
};

export const ogrHasAutoGeneratedClientIDOptionEnable = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.preferences?.autoGeneratedClientID;
};

export const orgHasClientNameOptionEnable = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.preferences?.hasClientName;
};

export const orgHasRemoveOpenFields = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.preferences?.removeOpenFields;
};

export const orgHasAllowedToCollectData = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.dataCollectionEnabled;
};

export const orgHasEnableOrganizationReports = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.preferences?.enableOrganizationReports;
};

export const showTimeRelatedDataInReports = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.preferences?.showTimeRelatedDataInReports;
};

export const showTimeRelatedDataInSessionReports = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.preferences?.showTimeRelatedDataInSessionReports;
};

export const isOrgHasClientNameRegex = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.preferences?.clientNameRegex;
};

export const isOrgHasLogo = (user = null) => {
  if (user && user?.organization !== null) {
    return user?.organization?.hasLogo || 0;
  }
  const { user: userFromState } = useState('auth', ['user']);

  if (userFromState.value && userFromState.value?.organization !== null) {
    return userFromState?.value?.organization?.hasLogo;
  }
};

export const getOrganizationShareOption = () => {
  const { user: userFromState } = useState('auth', ['user']);
  return userFromState.value?.organization?.preferences?.selfPracticeShareOptions;
};
