import { useAxios } from '@/use/use-axios';
import { environment } from '@/env';

import { organizationRoles } from '@packages/mongodb';
import { isAdminUser, isContributorUser, getCurrentLanguageObject, forceUpdate, getCurrentLanguageId } from '@/utils/helpers';

export const getAllDomains = () => useAxios({ url: `/api/v1/activity-domain` });

export const getAllDomainsTypes = () => {
  const currentLangId = getCurrentLanguageObject(false)?.language?._id;
  const query = isAdminUser() || isContributorUser() ? '' : `?query={"hiddenInLanguages": {"$nin":"${currentLangId}"},"isPublished":true}`;

  return useAxios({ url: `/api/v1/activity-type${query}` });
};

export const getAllCategory = () => useAxios({ url: `/api/v1/category` });
export const getAllSubcategory = () => useAxios({ url: `/api/v1/subcategory` });
export const getAllRoles = () => useAxios({ url: `/api/roles`, method: 'post' });
export const getLanguage = (langCode, defaultWebsite) => useAxios({ url: `/api/v1/lang/${langCode}?defaultWebsite=${defaultWebsite}` });

export const getArticulationShallow = (query) =>
  useAxios({
    url: `/api/v1/articulation?select=name,topCategory,category,title,attachedRoles,display,blends,letter,tagInfo,isHidden&query=${JSON.stringify(
      query
    )}`,
  });
export const getArticulationBySyllable = (data) => useAxios({ url: `/api/v1/syllable-articulations`, method: 'post', data });
export const getStripsWithActivity = (data) => useAxios({ url: `/api/v1/strip/activity`, method: 'post', data });

export const loginApi = (data) => useAxios({ url: `/api/auth/login`, method: 'post', data });

export const logutFromClalit = (provider, email) =>
  useAxios({
    url: `/api/auth/logout/sso/${provider}`,
    method: 'post',
    data: email,
  });

export const registerApi = (data) => useAxios({ url: `/api/auth/register`, method: 'post', data });
export const recoveryAccountApi = (data) => useAxios({ url: `/api/auth/recovery`, method: 'post', data });
export const requestContentApi = (data) => useAxios({ url: `/api/v1/request-content`, method: 'post', data });
export const reverifyApi = () => useAxios({ url: `/api/auth/reverify`, method: 'post' });

export const getAllBoardsWithActivity = (boardId) => useAxios({ url: `/api/v1/board/${boardId}/activity` });
export const createBoardApi = (data) => useAxios({ url: `/api/board`, method: 'post', data });

export const updateUserSurveyStatistics = (id, data) => useAxios({ url: `/api//user/survey-statistics/${id}`, method: 'post', data });
export const updateUserSelectedClient = (clientId) => {
  return useAxios({
    url: `/api//user/update-selected-client`,
    method: 'post',
    data: { clientId },
    headers: { updateUserData: true },
  });
};

export const getUserSurveys = () => useAxios({ url: `/api//user/surveys` });
export const updateUserInState = () => useAxios({ url: `/api/v1/user/user-by-token` });
export const renameBoard = (id, data) => useAxios({ url: `/api/v1/board/rename/${id}`, method: 'post', data });
export const deleteBoard = (id) => useAxios({ url: `/api/board/${id}`, method: 'delete' });
export const getAllBoards = () => useAxios({ url: `/api/board` });
export const findCouponOne = (data) => useAxios({ url: `/api/v1/checkout/coupon`, method: 'post', data }); //?query={"code": "${id}" }` });

export const checkoutApproved = (data) => useAxios({ url: `/api/v1/checkout-approved`, method: 'post', data });
export const cancelSubscription = () => useAxios({ url: `/api/v1/cancel-subscription`, method: 'post' });

export const putSurveyResultsApi = (data) => useAxios({ url: `/api/v1/survey-results`, method: 'post', data });

export const updatePurchase = (data) => useAxios({ url: `/api/v1/update-purchase`, method: 'post', data });

// export const inviteUser = (data) => useAxios({ url: `/api/auth/invite`, method: 'post', data });
// export const inviteEmail = (data) => useAxios({ url: `/api/auth/invite-email`, method: 'post', data });

export const sendEmailToAdmin = (data) => useAxios({ url: `/api/mail-admin`, method: 'post', data });

export const findActivityOne = (id) => {
  return useAxios({ url: `/api/v1/activity/${id}?populate=[{"path":"published", "populate": [{"path": "language"}]}]` });
};

export const getUserDefinedActivity = (id) =>
  useAxios({
    url: `/api/user-defined-activity/${id}`,
  });
export const findSurveyOne = (id) => useAxios({ url: `/api/v1/survey/${id}` });

export const findArticulationOne = (id) => useAxios({ url: `/api/v1/articulation/${id}` });

export const findPlanOne = (plan) => useAxios({ url: `/api/v1/plan/${plan}` });

export const checkoutWithCoupon = (planId, data) => useAxios({ url: `/api/v1/checkout/coupon/${planId}`, method: 'post', data }); //?query={"code": "${id}" }` });

export const getCheckoutSession = (plan, data) => useAxios({ url: `/api/v1/checkout/${plan}`, method: 'post', data });
// export const findPlanOne = (plan) => useAxios({ url: `/api/v1/checkout/${plan}`, method: 'post`});

// export const deleteAccount = (plan) => useAxios({ { url: `/api/auth/delete-account`, method: 'post', data: plan });
// export const cancelInvite = (data) => useAxios({ { url: `/api/auth/uninvite`, method: 'post', data });

// export const currentUser = () => useAxios({ url: `/api/auth/me` });
export const updateUserName = (data) => useAxios({ url: `/api/auth/user/name`, method: 'post', data });
// export const updateUserProfile = (data) => useAxios({ url: `/api/auth/user/profile`, method: 'post', data });

export const searchActivities = (searchQuery) => useAxios({ url: `/api/v1/activity`, params: searchQuery });

export const reportExploreActivity = (data) => useAxios({ url: `/api/v1/report-explore-activity-failure`, method: 'post', data });

export const getPlans = () => useAxios({ url: `/api/v1/plan` });

export const unpinFromBoard = (boardId, activityId) =>
  useAxios({
    url: `/api/v1/board/unpin/${boardId}`,
    method: 'post',
    data: { activityId },
  });

export const renameUser = (username) => useAxios({ url: `/api/auth/user/name`, method: 'post', data: { username } });

export const updateProfile = (profile) => useAxios({ url: `/api/auth/profile`, method: 'put', data: { profile } });

export const updateOrganization = (id, organization) => useAxios({ url: `/api/v1/organization/${id}`, method: 'put', data: organization });

export const changePasswordApi = (form, token) =>
  useAxios({ url: `/api/auth/password`, method: 'post', data: form, headers: { Authorization: `bearer ${token}` } });

export const addOrRemoveActivityFromBoard = (boardId, activity) =>
  useAxios({ url: `/api/board/${boardId}/items`, method: 'put', data: activity });

export const purchase = ({ couponCode, plan, licenses }) =>
  useAxios({ url: `/checkout/coupon`, method: 'post', data: { couponCode, plan, licenses } });

export const filterStats = (searchQuery) => useAxios({ url: `/api/v1/filter`, params: searchQuery });

export const getMarkdownResource = (resourcePath) => useAxios({ url: `${environment.cdn}/docs/${resourcePath}` });

export const getConfigResource = (configPath) => useAxios({ url: `${environment.cdn}/config/${configPath}.json${forceUpdate()}` });

export const contactUs = (data) => useAxios({ url: `/api/v1/contactus`, method: 'post', data });

export const getProfile = () => useAxios({ url: `/api/auth/profile`, method: 'post' });

// export const sendInviteEmail = (data) => useAxios({ url: `/api/auth/invite-email`, method: 'post', data });

export const deleteAccount = (data) => useAxios({ url: `/api/auth/delete-account`, method: 'post', data });

export const cancelInvite = (data) => useAxios({ url: `/api/auth/uninvite`, method: 'post', data });

export const sendInvite = (data) => useAxios({ url: `/api/auth/invite`, method: 'post', data });

export const printCount = () => useAxios({ url: `/api/print/count` });

export const authorizeApi = ({ token }) =>
  useAxios({ url: `/api/auth/authorize`, method: 'post', headers: { Authorization: `bearer ${token}` } });

export const invoicesApi = () => useAxios({ url: `/api/invoices`, method: 'post' });

export const removeMember = (data) => useAxios({ url: `/api/auth/remove-member`, method: 'post', data });

export const echoApi = () => useAxios({ url: `/api/auth/echo`, method: 'post' });

export const resendInvitation = (data) => useAxios({ url: `/api/auth/reinvite`, method: 'post', data });

export const getOrganizationClients = (organizationId, searchString = null) => {
  const query = {
    ...(searchString && {
      $or: [{ name: { $regex: `${searchString}`, $options: 'i' } }, { identifier: { $regex: `${searchString}`, $options: 'i' } }],
    }),
    organizationId: `${organizationId}`,
  };

  return useAxios({
    url: `/api/v1/client?select=name,identifier&limit=1000&sort=name&query=${JSON.stringify(query)}`,
  });
};

export const getOrganizationClientsWithRecent = (organizationId, date, searchString = null) => {
  const payload = {
    query: {
      ...(searchString && {
        $or: [
          { name: { $regex: `${searchString.name}`, $options: 'i' } },
          { identifier: { $regex: `${searchString.identifier}`, $options: 'i' } },
        ],
      }),
      organizationId: `${organizationId}`,
    },
    limit: 1000,
    date,
  };

  return useAxios({
    url: `/api/v1/get-clients-with-recent`,
    method: 'post',
    data: payload,
  });
};

export const getOrganizationsForSelect = () => useAxios({ url: `/api/v1/organization?select=name` });
export const getUsersForSelect = (query) => useAxios({ url: `/api/v1/user?select=name&query=${JSON.stringify(query)}` });

export const createClientSession = (data) => useAxios({ url: `/api/v1/client-session`, method: 'post', data });

export const updateClientSession = (sessionClient) =>
  useAxios({
    url: `/api/v1/client-session/${sessionClient._id}`,
    method: 'put',
    data: sessionClient,
  });

export const getClientSession = (clientId, userId) => {
  const query = {
    $and: [{ clientId }, { userId }],
  };

  return useAxios({ url: `/api/v1/client-session?limit=1&sort=-createdAt&query=${JSON.stringify(query)}` });
};

export const syncRemovedRecentUsers = (data) =>
  useAxios({ url: `/api/auth/user/removed-recent-users`, method: 'post', data: { removedRecentUsersIds: data } });

export const getInuseUserAndClientCounts = (orgId) => {
  return Promise.all([
    useAxios({ url: `/api/v1/user/count?query={"organization":"${orgId}"}` }),
    useAxios({ url: `/api/v1/client/count?query={"organizationId":"${orgId}"}` }),
  ]);
};

export const getInuseUsersCount = (orgId) => {
  return useAxios({ url: `/api/v1/user/count?query={"organization":"${orgId}"}` });
};

export const getInuseClientsCount = (orgId) => {
  return useAxios({ url: `/api/v1/client/count?query={"organizationId":"${orgId}"}` });
};

export const getUsersOfOrg = (orgId, filters) => {
  const query = {
    ...(filters.searchText && {
      search: filters.searchText,
    }),

    ...(filters.statusValue && {
      organizationStatus: filters.statusValue,
    }),

    ...(filters.roleValue && {
      organizationRole: filters.roleValue,
    }),

    organization: orgId,
  };

  return useAxios({ url: `/api/v1/user-details`, method: 'post', data: query });
};

export const getClientOfOrg = (orgId, filters) => {
  const query = {
    ...(filters.searchText && {
      $or: [
        { name: { $regex: `${filters.searchText}`, $options: 'i' } },
        { identifier: { $regex: `${filters.searchText}`, $options: 'i' } },
      ],
    }),

    organizationId: orgId,
  };

  return useAxios({ url: `/api/v1/client?sort=name&query=${JSON.stringify(query)}` });
};

export const updateUserOrganizationRoleByAdmin = (data) =>
  useAxios({ url: `/api/auth/user/organization-role/by-admin`, method: 'post', data });

export const isEmailAlreadyExistInOrg = async (email, organization) => {
  const query = {
    $and: [{ email }, { organization }],
  };
  const res = await useAxios({ url: `/api/v1/user/count?query=${JSON.stringify(query)}` });
  return res.data?.count === 0 ? false : true;
};

export const createOrgUser = (data) => useAxios({ url: `/api/v1/create-org-user`, method: 'post', data });
export const updateOrgUser = (data) => useAxios({ url: `/api/v1/update-org-user`, method: 'post', data });
export const deleteOrgUser = (data) => useAxios({ url: `/api/v1/remove-org-user`, method: 'put', data });
export const deleteOrgClient = (data) => useAxios({ url: `/api/v1/remove-org-client`, method: 'put', data });
export const createOrgClient = (data) => useAxios({ url: `/api/client`, method: 'post', data });
export const updateOrgClient = (data) => useAxios({ url: `/api/client`, method: 'patch', data });

export const getOrgUser = (userId) => useAxios({ url: `/api/v1/user/?query={"_id":"${userId}"}` });
export const getUserShallow = (id) =>
  useAxios({ url: `/api/v1/user/${id}?select=preferences,organization,selfPractice&populate=[{"path":"organization"}]` });

export const updateUser = (id, user) => useAxios({ url: `/api/v1/user/${id}`, method: 'put', data: user });

export const isOrgHasAnotherAdmin = async (organization) => {
  const query = {
    $and: [{ organizationRole: organizationRoles.ADMIN.value }, { organization }],
  };
  const res = await useAxios({ url: `/api/v1/user/count?query=${JSON.stringify(query)}` });
  return res.data?.count > 1 ? true : false;
};

export const acceptOrgInvitation = (token) =>
  useAxios({ url: `/api/v1/accept-org-invitation`, method: 'post', headers: { Authorization: `bearer ${token}` } });

export const getUserByToken = (token) =>
  useAxios({ url: `/api/v1/user/user-by-token`, method: 'get', headers: { Authorization: `bearer ${token}` } });

export const resendOrgInvitation = (data) => useAxios({ url: `/api/v1/resend-org-invitation`, method: 'post', data });

export const createActivityLog = (data) => useAxios({ url: `/api/v1/client-activity`, method: 'post', data });

export const updateActivityLog = (id, data) => useAxios({ url: `/api/v1/client-activity/${id}`, method: 'put', data });

// export const getActivitiesForRecommendation = () =>
//   useAxios({ url: `/api/v1/activity?limit=30&populate=[{"path":"type", "populate": [{"path": "domain"}]}]`, method: 'get' });

export const getActivitiesForRecommendation = (data) => useAxios({ url: `/api/v1/recommendation-activity`, method: 'post', data });

export const createActivityClientFeedBack = (data) => useAxios({ url: `/api/v1/activity-client-feedback`, method: 'post', data });

export const createRecommendedActivitySelection = (data) =>
  useAxios({ url: `/api/v1/user-recommendation-selection`, method: 'post', data });

export const getClientDashboardStatistics = (clientID, from, to, dashboardType) =>
  useAxios({
    url: `/api/v1/client-dashboard-statistics/${clientID}?from=${from}&to=${to}&dashboardType=${dashboardType}`,
    method: 'get',
  });

export const getClientDashboardSessionStatistics = (data) =>
  useAxios({
    url: `/api/client-dashboard-statistics/`,
    method: 'post',
    data,
  });

export const getOrgDashboardStatistics = (data) =>
  useAxios({
    url: `/api/v1/org-dashboard-statistics`,
    method: 'post',
    data,
  });

export const getOrgDashboardSessionStatistics = (data) =>
  useAxios({
    url: `/api/org-dashboard-session-statistics`,
    method: 'post',
    data,
  });

export const getAdminClientDashboardStatistics = (data) =>
  useAxios({
    url: `/api/v1/admin-dashboard-statistics`,
    method: 'post',
    data,
  });

export const getAdminClientDashboardSessionStatistics = (data) =>
  useAxios({
    url: `/api/admin-dashboard-session-statistics`,
    method: 'post',
    data,
  });

export const createShareHomePracticeLink = (data) =>
  useAxios({
    url: `/api/v1/home-practice-detail`,
    method: 'post',
    data,
  });

export const updateShareHomePracticeLink = (id, data) => useAxios({ url: `/api/v1/home-practice/${id}`, method: 'put', data });

export const getHomePracticeUser = (userId) =>
  useAxios({ url: `/api/v1/user/?query={"_id":"${userId}"}&populate=[{"path": "organization"}]` });

export const homeDashboardStatistics = (query) =>
  useAxios({
    url: `/api/v1/home-practice-dashboard-statistics?query=${JSON.stringify(query)}`,
  });

export const getSelfPracticePerformance = (query) =>
  useAxios({
    url: `/api/v1/self-practice-performance/?query=${JSON.stringify(query)}`,
    method: 'get',
  });

export const getActivitiesCatalog = (data) =>
  useAxios({
    url: `/api/v1/catalog-activities`,
    method: 'post',
    data,
  });

export const updateCatalogActivities = (data) => useAxios({ url: `/api/v1/catalog-activities`, method: 'put', data });

export const getPhoneticsWordData = (data) =>
  useAxios({
    url: `/api/v1/phonetics-filter`,
    method: 'post',
    data,
  });

export const getPhoneticsWordDataAdvanced = (data) =>
  useAxios({
    url: `/api/v1/phonetics-filter-advanced`,
    method: 'post',
    data,
  });

export const getPhoneticsWordDataFromDetails = (data) =>
  useAxios({
    url: `/api/v1/phonetics-details`,
    method: 'post',
    data,
  });

export const getPhoneticsWordDataFromDetailsByAdvancedFilter = (data) =>
  useAxios({
    url: `/api/v1/phonetics-details-by-advanced-filter`,
    method: 'post',
    data,
  });
export const userAuditLogs = (data) => useAxios({ url: `/api/v1/user-audit-logs`, method: 'post', data });

export const makePhoneticSuggetion = (data) => useAxios({ url: `/api/v1/make-phonetics-suggetion`, method: 'post', data });

export const getPhoneticsLists = (langId) =>
  useAxios({
    url: `/api/phonetics-lists?language=${langId}`,
    method: 'get',
  });

export const createPhoneticsList = (data) => useAxios({ url: `/api/phonetics-lists`, method: 'post', data });

export const updatePhoneticsList = (data) => useAxios({ url: `/api/phonetics-lists/${data?._id}`, method: 'patch', data });

export const deletePhoneticsList = (phoneticsListId) => useAxios({ url: `/api/phonetics-lists/${phoneticsListId}`, method: 'delete' });

export const reorderPhoneticsOrgList = (data) => useAxios({ url: `/api/organization`, method: 'patch', data });

export const reorderPhoneticsClientList = (data) => useAxios({ url: `/api/client-preference`, method: 'patch', data });

export const getAllPhoneticsStructure = (query = null) =>
  useAxios({
    url: `/api/v1/phonetics-structure${query ? `?query=${JSON.stringify(query)}` : ''}`,
  });

export const reorderPhoneticsMyList = (profile, currentLangId) =>
  useAxios({ url: `/api/auth/profile`, method: 'put', data: { profile, currentLangId } });

export const getPhoneticsBlendsData = (phoneticsSoundId, currentWebSiteId) =>
  useAxios({
    url: `/api/v1/syllable-blends?phoneticSound=${phoneticsSoundId}&website=${currentWebSiteId}`,
    method: 'get',
  });

export const sendSelfPracticeSms = (data) => useAxios({ url: `/api/send-self-practice-sms`, method: 'post', data });

export const sendSelfPracticeEmail = (data) => useAxios({ url: `/api/self-practice/share-board-email`, method: 'post', data });

export const getBoardNameList = (query) => useAxios({ url: `/api/v1/lists?query=${JSON.stringify(query)}`, method: 'get' });

export const getPhoneticsSounds = (query) =>
  useAxios({ url: `/api/v1/phonetics-sounds?sort=weight&query=${JSON.stringify(query)}`, method: 'get' });

export const createBoardOrderLitsApi = (data) => useAxios({ url: `/api/order-list`, method: 'post', data });
export const updateBoardOrderDetails = (data) => useAxios({ url: `/api/order-list`, method: 'patch', data });

export const getOrderList = (contextId) => useAxios({ url: `/api/order-list/${contextId}` });

export const saveNewUserDefinedActivity = (data) =>
  useAxios({
    url: `/api/user-defined-activity`,
    method: 'post',
    data,
  });

export const getHomePractice = (linkId) =>
  useAxios({
    url: `/api/home-practice/${linkId}`,
  });
export const reportAutoLogoutIssue = (data) => useAxios({ url: `/api/v1/report-auto-log-out-issue`, method: 'post', data });

export const getLanguageLettersMappingData = () => useAxios({ url: `/api/language/letters`, method: 'get' });
